import { CodeForm } from '@features/user/ui/forms';
import { addNotification, Button } from '@ui';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RestorePasswordScreenTypes } from '../types';
import styles from './../../styles.module.scss';
import { useAuthTdLk } from '@features/api';
import { useHCaptcha } from '@eus/react-web-client/src/hooks/useHCaptcha';
import { SITE_KEY } from '@teledoctor/common/dist/consts/recaptcha';
import { getErrorTextForRestorePassword } from '@teledoctor/common/dist/lib/error-handlers/helpers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { useErrorMessage } from '@teledoctor/common/dist/features/shared/forms';
import { CheckFormCode } from '@eus/react-client';
import { ErrorOption } from 'react-hook-form';

interface Props {
  phone: string;
  changeScreen: Dispatch<SetStateAction<RestorePasswordScreenTypes>>;
  resetState: () => void;
  restorePasswordByPhone: (
    phone?: string | undefined,
    recaptchaToken?: string | undefined,
  ) => Promise<unknown>;
  verifyCode: (
    { code }: CheckFormCode,
    setError: (name: any, error: ErrorOption) => void,
  ) => void;
  getCodeAgainHandler: () => Promise<void>;
}

export const RestorePasswordByPhone = ({
  phone,
  changeScreen,
  resetState,
  restorePasswordByPhone,
  verifyCode,
  getCodeAgainHandler,
}: Props) => {
  const { checkIdentify } = useAuthTdLk();
  const { askHcaptchaToken, component, isCaptchaApiReady } =
    useHCaptcha(SITE_KEY);
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);
  const { ErrorMessage } = useErrorMessage();

  const restoreByPhone = useCallback(() => {
    checkIdentify(
      { login: phone },
      {
        onExist: async () => {
          const recaptchaToken = await askHcaptchaToken();
          restorePasswordByPhone(phone, recaptchaToken)
            .then(() => {
              setCodeHasBeenSent(true);
            })
            .catch((err) => {
              const errText = getErrorTextForRestorePassword(err);
              addNotification({
                id: 'restore-password-send-code-' + new Date(),
                message:
                  errText ||
                  'Произошла неизвестная ошибка. Попробуйте еще раз позже.',
                type: 'error',
                target: 'authorization',
              });
            });
        },
        onNotExist: () => {
          addNotification({
            id: 'restore-password-by-phone' + new Date(),
            message:
              ErrorMessage.phone_does_not_exist ||
              'Произошла неизвестная ошибка. Попробуйте еще раз позже.',
            type: 'error',
            target: 'authorization',
          });
        },
      },
      new FeedParams({
        throwNext: true,
        needShowLoader: true,
      }),
    );
  }, [
    ErrorMessage.phone_does_not_exist,
    checkIdentify,
    askHcaptchaToken,
    restorePasswordByPhone,
    phone,
  ]);

  useEffect(() => {
    if (isCaptchaApiReady) {
      restoreByPhone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaApiReady]);

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {component}
      {!!codeHasBeenSent && (
        <CodeForm
          phone={phone}
          requestCodeAgain={getCodeAgainHandler}
          onSuccessForm={verifyCode}
        />
      )}
      <Button
        appearance="textual"
        fullWidth
        title="Восстановить через E-mail"
        onClick={() => changeScreen('email')}
        className={styles['password-button']}
      />
    </>
  );
};
